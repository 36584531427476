<template>
   <div>
      <Slider />
      <Breadcrumbs v-if="post !== null"  :page="page" :items="breadcrumbs" color="white" />
        <section class="mb-10" v-if="post !== null">   
            <v-layout justify-space-around justify-center class="contact-layout">
                <v-flex xs9 sm9 offset-sm0 class="" align="" justify="" >
                        <v-card color="" tile flat>
                        <v-card-title primary-title class="">
                            <div class="">
                                <div class="zamu-sub-heading post-category">
                                    <span v-if="category !== null" class="capitalize">{{  category.category }},</span> 
                                    <span class="post-date ml-2">{{ formatDate(post.created_at , 'MMM DD, YYYY')}}</span>
                                </div>

                                <h3 class="headline zamu-heading mt-3">{{ post.title }}</h3>
                            </div>
                            </v-card-title>
                            <v-card-text>
                                   <v-card  flat  tile xs12 sm12  width="100%" dark class="blog-image-holder">
                                        <span class="blog-image" v-html="post.image"></span>
                                    </v-card>
                            </v-card-text>
                          <v-card-text>
                              <v-row no-gutters>
                                    <v-col lg="12" md="12" sm="12" xs="12" cols="12" class="">
                                        <v-card color="" flat tile width="100%">
                                         <span class="" v-html="post.content"></span>
                                        </v-card>
                                    </v-col>
                              </v-row>
                          </v-card-text>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
       <section class="mb-10 read-next">   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm0 class="mt-10">
                        <v-card flat tile color="" width="90%" class="transparent main-content-products">
                            <v-card-title primary-title alig class="justify-center" align="center" justify="center">
                               
                            </v-card-title>
                        </v-card>
                </v-flex>
            </v-layout>
       </section>
            
   </div>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
// import moment from 'moment';


export default {
    name: 'Post',
     components: {
      Slider: () => import('./slider'),
       Breadcrumbs: () => import('../../layout/Breadcrumb.vue'),
    },
    mixins: [coreMixin],

     data () {
       return {
            page: 'blog-post',
            post: null,
            category: null,
       };
    },
     computed: {
        breadcrumbs() {
            const data = [
                {
                text: 'All Blogs',
                disabled: false,
                href: '/blog',
                },
                {
                text: `${this.post.title}`,
                disabled: true,
                href: ``,
                },
            ];
            return data;

        }
     },

     async mounted() {
         const id = this.$route.params.id;
        this.post = await this.retrieveBlogPost(id);
        this.category = await this.retrieveBlogCategory(this.post.category);
    },

}
</script>

<style>

</style>